import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import { colors } from '../settings/colors';
import {getHeaderLogoUrl} from '../settings/utils';

const LOGO_URL = getHeaderLogoUrl();
const TABS = [
  {
    label: 'Home',
    href: '/',
    display: <SearchIcon />
  }
];

const Logo = ({ styleObject }) => {
  return (
    <Link to={'/'} style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', alignItems: 'end' }}>
      <CardMedia
        component='img'
        image={LOGO_URL}
        alt='image'
        sx={{ maxWidth: '80px', height: 'auto', ...styleObject }}
      />
    </Link>
  );
};

const Brand = ({ styleObject }) => {
  return (
    <Typography
      variant="h6"
      noWrap
      component="a"
      href="/"
      sx={{
        mr: 2,
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        ...styleObject
      }}
    >
    </Typography>
  );
};

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [tabs, setTabs] = useState(TABS);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onSettingClick = (setting) => {
    switch (setting) {
      case 'Logout':
        window.nblogout();
        break;
      case 'Account':
        navigate('/Account');
        break;
      default:
        handleCloseUserMenu();
    }

    handleCloseUserMenu();
  }

  return (
    <AppBar id='rdap-header' position="fixed" sx={{ height: '64px', backgroundColor: colors.purple }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Logo styleObject={{ display: { xs: 'none', md: 'flex' } }} />
          {/* <Brand styleObject={{ display: { xs: 'none', md: 'flex' } }} /> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {tabs.map(({ label, href, display }) => (
                <Link key={label} to={href} sx={{ textDecoration: 'none' }}>
                  <MenuItem>
                    <Typography textAlign='center'>{display}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Logo styleObject={{ display: { xs: 'flex', md: 'none' }}} />
          {/* <Brand styleObject={{ display: { xs: 'flex', md: 'none' }}} /> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {tabs.map(({ label, href, display }, index) => (
              <Link key={label} to={href} style={{ textDecoration: 'none', marginLeft: 15, marginTop: 8 }}>
                <Typography textAlign='center' color='white' sx={{ my: 'auto' }}>
                  {display}
                </Typography>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;